import { ContentType } from 'pleinchamp-api-client';

export type ServiceContentType = ContentType.PlcService | ContentType.AffiliateService;

export enum ApiStatusCode {
  ok = 200,
  created = 201,
  accepted = 202,
  noContent = 204,
  redirect = 302,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  internalServerError = 500,
}

export const NB_HOME_EVENTS = 4;
export const NB_HOME_NEWS_HEADLINES = 3;
export const NB_HOME_CUSTOM_NEWS_DESKTOP = 6;
export const NB_HOME_CUSTOM_NEWS_MOBILE = 4;
export const NB_HOME_MOST_VIEWED_NEWS = 4;
export const NB_LAST_GUIDES = 2;

function safeStaticPropsReturnValue(value: any) {
  return ['number', 'boolean', 'string'].includes(typeof value) ? value : value || null;
}

export function formatPropsForStatic(props: { [key: string]: any }, revalidate = 10) {
  const cleanedProps = Object.keys(props).reduce(
    (acc, key) => ({ ...acc, [key]: safeStaticPropsReturnValue(props[key]) }),
    {}
  );
  return { props: cleanedProps, revalidate };
}
