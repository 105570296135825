import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { useTranslation } from '@locales/useTranslation.hook';
import classnames from 'classnames';
import { HTMLPropsHeaders, managedComponents, TextProps } from './Text.types';

const Text: FC<PropsWithChildren<TextProps & HTMLPropsHeaders>> = forwardRef(
  (
    { tag, variant, flavour = 'dark', alignment = 'left', className, i18nKey, i18nOptions, children, ...rest },
    typoRef
  ) => {
    const i18nKeyNs = i18nKey ? i18nKey.split('.')[0] : 'common';
    const { t, ready } = useTranslation(i18nKeyNs);
    const rootClass = classnames('typography', variant, flavour, `text-${alignment}`, className);

    const innerHtml = (
      <>
        {i18nKey && t(i18nKey, i18nOptions)} {children}
      </>
    );

    let Component: managedComponents;
    if (tag) {
      Component = tag;
    } else if (
      variant !== 'big' &&
      variant !== 'new-big' &&
      variant !== 'new-h3' &&
      variant !== 'tiny' &&
      variant !== 'small' &&
      variant !== 'new-small' &&
      variant !== 'bold' &&
      variant !== 'new-bold' &&
      variant !== 'new-p' &&
      variant !== 'new-bold-p' &&
      variant !== 'quote' &&
      variant !== 'tag' &&
      variant !== 'tiny-title' &&
      variant !== 'date' &&
      variant !== 'menu-link' &&
      variant !== 'p-style-h2' &&
      variant !== 'p-style-h3' &&
      variant !== 'new-p-style-h3' &&
      variant !== 'p-h3-upper-style' &&
      variant !== 'p-style-h4' &&
      variant !== 'margin'
    ) {
      Component = variant;
    } else {
      Component = 'p';
    }
    if (ready) {
      if (Component === 'span') {
        return (
          <Component ref={typoRef as React.Ref<HTMLSpanElement>} className={rootClass} {...rest}>
            {innerHtml}
          </Component>
        );
      }
      return (
        // @ts-ignore
        <Component ref={typoRef as React.Ref<HTMLHeadingElement>} className={rootClass} {...rest}>
          {innerHtml}
        </Component>
      );
    }
    return <></>;
  }
);

export { Text };
