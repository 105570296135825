/* eslint-disable no-inline-comments */
export const Constants = {
  EMPTY_ARRAY: [],
  FEATURED_NEWS_LIMIT: 2,
  JOURNAL_DEFAULT_STOCK_PRODUCT_TYPES_ID: [10, 14], // 10 : blé tendre in prod / 14 : mais in prod
  NB_METEO_HOUR_BY_HOUR_DAYS_DISPLAYED: 15,
  PRICE: '9,90',
  SEARCH_RESULT_PER_PAGE: [6, 12],
  SOCIAL: {
    FACEBOOK_URL: 'https://www.facebook.com/pleinchampcom',
    LINKEDIN_URL: 'https://www.linkedin.com/company/pleinchampcom',
    TIKTOK_URL: 'https://www.tiktok.com/@pleinchamp_agriculture',
    X_URL: 'https://x.com/Pleinchamp',
    YOUTUBE_URL: 'https://www.youtube.com/user/lachainepleinchamp',
  },
  TEN_FRAMES: 166, // 166 corresponds to 10 frames at 60 Hz
};
