import { ActivityCode, InterestCode, ThematicCode } from 'pleinchamp-api-client';

export enum SearchContentType {
  All = 'all',
  Article = 'article',
  Event = 'event',
  Service = 'service',
  Guide = 'guide',
}

export type SearchQueryParams = {
  query: string;
  contentType: SearchContentType;
  interests: InterestCode[];
  activities: ActivityCode[];
  thematics: ThematicCode[];
  pageNb?: number;
};

export const contentTypeInUrl: { [key in SearchContentType]: string } = {
  all: 'tous',
  article: 'actualites',
  event: 'evenements',
  guide: 'guides',
  service: 'services',
};

export const activityInUrl: { [key in ActivityCode]: string } = {
  bigCrops: 'grandes-cultures',
  breeding: 'elevage',
  fruitsVegetables: 'fruits-et-legumes',
  wine: 'vigne-et-vin',
};

export const interestInUrl: { [key in InterestCode]: string } = {
  agriculturalSituation: 'agricultural-situation',
  agroecology: 'agroecology',
  aidAndIncome: 'aid-and-income',
  climate: 'climat',
  coursesMarket: 'courses-market',
  farmIncome: 'farm-income',
  innovation: 'innovation',
  installationAndTransmission: 'installation-and-transmission',
  machinery: 'machinisme',
  marketingOutlets: 'commercialisation-et-debouches',
  politics: 'politique',
  productionTechniques: 'techniques-de-production',
  regulatory: 'regulatory',
  sanitary: 'sanitary',
};

export const thematicInUrl: { [key in ThematicCode]: string } = {
  farmManagementThematic: 'gestion-de-l-exploitation',
  innovationThematic: 'innovation',
  installationTransmission: 'installation-et-transmission',
  transitions: 'transitions',
};

export enum FilterType {
  Themes,
  Activities,
}

export interface Filter {
  value: InterestCode | ActivityCode;
  active: boolean;
  type: FilterType;
}
