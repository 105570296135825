/* eslint-disable @typescript-eslint/no-explicit-any */
import { CACT_CATEGORIES, TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import { PlcCustomATInfo } from './TagPlan.types';

// From https://developers.atinternet-solutions.com/javascript-fr/contenus-javascript-fr/pages-javascript-fr/
export type ATPageInfo = {
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  customObject?: any;
};

export type ATUserInfo = { category: null | string };

// From https://developers.atinternet-solutions.com/javascript-fr/contenus-javascript-fr/clics-javascript-fr/
export type ATClickInfo = {
  elem?: any;
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  type?: 'exit' | 'download' | 'action' | 'navigation';
  customObject?: any;
};

declare const ATInternet: any;
export type Tag = any;

export type PlcATPageInfo = Omit<ATPageInfo, 'name' | 'level2'> & PlcCustomATInfo;
export type PlcATClickInfo = Omit<ATClickInfo, 'name' | 'level2'> & PlcCustomATInfo;

export class TagPlan {
  private isInstanciated = false;

  private enabled = false;

  private tagObject: Tag;

  public init() {
    const hasCookieBeenConsented = TagCommanderService.getIABConsentString();
    if (this.isInstanciated || !window || !hasCookieBeenConsented) {
      return;
    }
    try {
      const tag = new ATInternet.Tracker.Tag({ secure: true });
      if (tag) {
        tag.privacy.setVisitorOptin();
        if (!TagCommanderService.isCookieConsentByCategorieAccepted(CACT_CATEGORIES.PLC_CAT_MESURE_AUDIENCE)) {
          tag.privacy.setVisitorMode('cnil', 'exempt');
        }
        this.tagObject = tag;
        this.isInstanciated = true;
        this.enabled = true;
      }
    } catch (err: any) {
      console.error(err);
    }
  }

  public ATsetConsent = () => {
    const isConsent = this.tagObject.privacy.getVisitorMode().name !== 'no-consent';
    if (isConsent) {
      this.tagObject.privacy.setVisitorMode('default', 'no-consent');
    } else if (!TagCommanderService.isCookieConsentByCategorieAccepted(CACT_CATEGORIES.PLC_CAT_MESURE_AUDIENCE)) {
      this.tagObject.privacy.setVisitorMode('cnil', 'exempt');
    } else {
      this.tagObject.privacy.setVisitorOptin();
    }
  };

  public tagPage({
    name,
    level2,
    ...rest
  }: Omit<PlcATPageInfo, 'tagPageNameI18nArray'> & { name: ATClickInfo['name'] }) {
    const interval = setInterval(() => {
      if (!this.tagObject || !this.enabled) {
        return;
      }
      this.tagObject.page.set({
        level2: level2 ? level2.toString() : undefined,
        name,
        ...rest,
      });
      this.tagObject.dispatch();
      clearInterval(interval);
    }, 800);
  }

  public tagClick({
    name,
    type = 'action',
    level2,
    ...rest
  }: Omit<PlcATClickInfo, 'tagPageNameI18nArray'> & { name: ATClickInfo['name'] }) {
    if (!this.tagObject || !this.enabled) {
      return;
    }
    this.tagObject.click.send({
      level2: level2 ? level2.toString() : undefined,
      name,
      type,
      ...rest,
    });
  }

  public tagVisitor(visitor: ATUserInfo) {
    if (!this.tagObject || !this.enabled) {
      return;
    }
    if (this.tagObject.identifiedVisitor) {
      try {
        this.tagObject.identifiedVisitor.set(visitor);
      } catch (error) {
        console.info('ATInternet error tagVisitor - visitor : ', visitor, ' tagObject ', this.tagObject, error);
      }
    }
  }

  public unsetVisitor() {
    if (!this.tagObject || !this.enabled) {
      return;
    }
    if (this.tagObject.identifiedVisitor) {
      this.tagObject.identifiedVisitor.unset();
      this.tagObject.dispatch();
    }
  }
}

export const TagPlanService = new TagPlan();
