// The fallback number is the production value
export const ADVERT_SITE_ID = process.env.NEXT_STATIC_ADS_SITE_ID || 341572;

export const ADVERT_PAGE_ID = {
  Actu: process.env.NEXT_STATIC_ADS_PAGE_ACTU || 1194261,
  Event: process.env.NEXT_STATIC_ADS_PAGE_EVENTS || 1194262,
  Guide: process.env.NEXT_STATIC_ADS_PAGE_GUIDE || 1197230,
  GuideDetail: process.env.NEXT_STATIC_ADS_PAGE_GUIDE_DETAIL || 1228411,
  Home: process.env.NEXT_STATIC_ADS_PAGE_JOURNAL || 1194256,
  InnovationHome: process.env.NEXT_STATIC_ADS_PAGE_ACTU || 1194261,
  MeteoHome: process.env.NEXT_STATIC_ADS_PAGE_METEO_HOME || 1194258,
  MeteoHourByHour: process.env.NEXT_STATIC_ADS_PAGE_HOUR_BY_HOUR || 1194259,
  MeteoRadar: process.env.NEXT_STATIC_ADS_PAGE_RADAR || 1194273,
  ServicesAffiliate: process.env.NEXT_STATIC_ADS_PAGE_AFFILIATE_SERVICES || 1228410,
  ServicesHome: process.env.NEXT_STATIC_ADS_PAGE_SERVICES_HOME || 1194268,
  ServicesPleinchamp: process.env.NEXT_STATIC_ADS_PAGE_PLC_SERVICES || 1194269,
  StocksDetails: process.env.NEXT_STATIC_ADS_PAGE_STOCKS_DETAILS || 1194267,
  StocksHome: process.env.NEXT_STATIC_ADS_PAGE_STOCKS_HOME || 1194264,
};

export enum ADVERT_FORMAT_ID {
  // Habillage
  Arch = 64528,
  // Pavé
  Block = 95424,
  // Vidéo in line
  InlineVideo = 79257,
  // Grande native
  NativeAd = 88286,
  // Grande native bis
  NativeAdBis = 64529,
  // Petite native
  SmallNative = 88518,
  // Grand angle
  WideAngle = 64527,
  // Mega banner mobile
  MegaBanner = 64525,
}
