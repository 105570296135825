import { createContext } from 'react';
import { urlEnum } from '@utils/url';
import { ConnectionStateEnum, DeviceEnum, TcVarsOptions } from './TagCommander.types';

export const initialTcVars: TcVarsOptions = {
  env_device: DeviceEnum.Desktop,
  env_name_site: 'Pleinchamp',
  env_working: 'dev',
  etat_de_connexion: ConnectionStateEnum.ANONYMOUS,
  niveau3: '',
  page_name: urlEnum.home,
  page_url: '/',
  statut_utilisateur: false,
};

const initialState = {
  tcReady: false,
  tcVars: initialTcVars,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateTcVars: (_newTcVars: TcVarsOptions) => {},
};

const TagCommanderContext = createContext(initialState);
TagCommanderContext.displayName = 'TagCommanderContext';

export default TagCommanderContext;
