/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, forwardRef, PropsWithChildren, ReactElement, useMemo, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { Backdrop } from '@components/Backdrop/Backdrop.component';
import { IconButton } from '@components/buttons/IconButton/IconButton.component';
import { Text } from '@components/Text/Text.component';
import { CloseIcon } from '@icons/Close.icon';
import { useTranslation } from '@locales/useTranslation.hook';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { Spacing } from '@styles/Spacing.style';
import classnames from 'classnames';
import { isElement } from 'react-dom/test-utils';
import styles from './Modal.module.scss';
import { ModalProps } from './Modal.types';

const StyledDialog = withStyles((theme) => ({
  paper: {
    padding: `${Spacing.s}px ${Spacing.m}px ${Spacing.m}px`,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '500px',
      padding: `${Spacing.m}px ${Spacing.l}px min(${Spacing.xl}px, 5vh)`,
    },
  },
}))(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    '&.MuiDialogTitle-root': {
      borderBottom: `2px solid ${PlcColor.GREY}`,
      marginBottom: '20px',
      paddingBottom: '24px',
    },
    display: 'flex',
    flexDirection: 'row',
    marginBottom: Spacing.basis,
    marginTop: Spacing.basis,
    padding: 0,
  },
})(DialogTitle);
const StyledDialogContent = withStyles({ root: { display: 'flex', flexDirection: 'column', padding: 0 } })(
  DialogContent
);
const StyledDialogActions = withStyles({ root: { marginTop: Spacing.m, padding: 0 } })(DialogActions);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Transition: FC<PropsWithChildren<TransitionProps & { children?: ReactElement<any, any> }>> = forwardRef(
  (props, ref) => {
    return <Slide ref={ref} direction="up" {...props} />;
  }
);

const StyledModal: FC<ModalProps> = forwardRef(
  (
    {
      close,
      children,
      className,
      title,
      visible,
      titleI18nKey,
      actions,
      rotateOnSmallDevice = false,
      withCustomFocusLock,
      ...rest
    },
    modalRef
  ) => {
    const { t } = useTranslation(['common']);
    const paperRef = useRef<HTMLElement>(null);
    const isMobile = useIsBreakpointDown(Breakpoint.s);
    const rootClassName = classnames(styles['plc-modal'], className, {
      'force-full-screen': rotateOnSmallDevice,
    });
    const titleElement = useMemo(() => {
      if (!title && !titleI18nKey) {
        return null;
      }
      return title && isElement(title) ? (
        title
      ) : (
        <Text i18nKey={titleI18nKey} tag="div" variant="h3">
          {title}
        </Text>
      );
    }, [title, titleI18nKey]);

    const closeButtonInnerHtml = (
      <IconButton onClick={close} title={t('common.close')}>
        <CloseIcon fillColor={PlcColor.GREEN} stroke={PlcColor.GREEN} width={24} />
      </IconButton>
    );
    const closeButton = withCustomFocusLock ? (
      <FocusLock className={styles['close-button-wrapper-auto']} group="modalActiveSlide">
        {closeButtonInnerHtml}
      </FocusLock>
    ) : (
      <div className={styles['close-button-wrapper-auto']}>{closeButtonInnerHtml}</div>
    );

    return (
      <StyledDialog
        ref={modalRef}
        BackdropComponent={Backdrop}
        className={rootClassName}
        fullScreen={isMobile}
        onClose={close}
        open={visible}
        PaperProps={{ ref: paperRef }}
        scroll="paper"
        TransitionComponent={Transition}
        {...rest}
      >
        <StyledDialogTitle className={rotateOnSmallDevice ? 'plc-mb-0' : ''} disableTypography={true}>
          {titleElement}
          {closeButton}
        </StyledDialogTitle>
        <StyledDialogContent>
          <div className={classnames(styles['modal-content'], styles['display-orientation-warning'])}>
            <Text i18nKey="common.modale.warn-user-to-rotate-device" variant="p" />
          </div>
          <div className={styles['modal-content']}>{children}</div>
        </StyledDialogContent>
        {actions && <StyledDialogActions>{actions}</StyledDialogActions>}
      </StyledDialog>
    );
  }
);
export { StyledModal };
