export enum PlcColor {
  BLUE_LAND = '#2d3049',
  GREEN_LEAF = '#84af4b',
  RED_PEPPER = '#ff574b',
  YELLOW_SUN = '#ffd314',
  GREY_CLOUD_MEDIUM = '#d5d6df',
  GREY_CLOUD_DARK = '#85889a',
  GREY_DARK = '#666E8A',
  GREY = '#ECEDF0',
  WHITE = '#ffffff',
  NUDE_WHEAT = '#FFA569',
  GREEN_DARK_LEAF = '#00A378',
  YELLOW_DARK_SUN = '#FFC714',
  GREY_CLOUD_LIGHT = '#f2f3f7',
  GREY_CLOUD_ULTRALIGHT = '#f8f9fa',
  DARK_GREEN = '#4E7914',
  GREEN = '#006A4E',
  LIGHT_GREEN = '#A0D7A2',
  LIGHT_GREEN_2 = '#D8EAD7',
  BLACK = '#000000',
}

export enum PlcFonts {
  Montserrat = 'Montserrat, sans-serif',
  Noto = 'Noto Serif, serif',
}
