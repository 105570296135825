import { isServer } from './server';

export enum STORAGE_KEY {
  SHOW_FREEMIUM = 'SHOW_FREEMIUM',
  CLE_VERSION = 'CLE_VERSION_7',
}

function setItem(key: STORAGE_KEY, value: string | number | boolean | {}): void {
  if (isServer()) {
    return;
  }
  window.localStorage.setItem(key, JSON.stringify(value));
}

function getItem<T>(key: STORAGE_KEY): null | T {
  if (isServer()) {
    return null;
  }
  const data = window.localStorage.getItem(key);
  if (data === null) {
    return null;
  }
  return JSON.parse(data);
}

export const StorageService = {
  getItem,
  setItem,
};
