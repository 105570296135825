import { StyledModal } from './StyledModal.component';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { GenericModalProps, ModalProps } from './Modal.types';

const GenericModal: ForwardRefRenderFunction<GenericModalProps> = (_: any, modalRef: React.Ref<unknown> | undefined) => {
  const close = () => {};
  const [modalProps, setModalProps] = useState<ModalProps>({ close, visible: false });

  function show(props: Partial<ModalProps>) {
    return setModalProps({ close, ...props, visible: true });
  }

  function hide() {
    return setModalProps({ ...modalProps, visible: false });
  }

  function onClose() {
    if (modalProps.close) {
      modalProps.close();
    }
    hide();
  }

  useImperativeHandle(modalRef, () => ({
    hide,
    show,
  }));

  return <StyledModal {...modalProps} close={onClose} />;
};

export default forwardRef(GenericModal);
